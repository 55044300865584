import { useContext, useEffect } from 'react'
import { postCounterMetric } from '../../../digital-advertising/api/digitalAdvertisingApiClient'
import { DidomiContext } from '../Didomi/DidomiContext'

const detectAdBlock = async () => {
    let adBlockEnabled = false
    const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
    const astUrl = 'https://acdn.adnxs.com/ast/ast.js'
    const publishertagUrl = 'https://static.criteo.net/js/ld/publishertag.prebid.117.js'
    try {
        await fetch(new Request(googleAdUrl), { method: 'HEAD' })
        await fetch(new Request(astUrl), { method: 'HEAD' })
        await fetch(new Request(publishertagUrl), { method: 'HEAD' })
    } catch (_error) {
        adBlockEnabled = true
    } finally {
        try {
            if (adBlockEnabled) {
                await postCounterMetric('ADBLOCKER_DETECTED')
            } else {
                await postCounterMetric('ADBLOCKER_NOT_DETECTED')
            }
        } catch (e) {
            console.error('error posting dac metric', e)
        }
    }
}

export const useAdblockDetection = async () => {
    const { isConsentGivenForVendor, isReady } = useContext(DidomiContext)
    useEffect(() => {
        if (isReady) {
            const googleConsent = isConsentGivenForVendor('didomi:google')
            const xandrConsent = isConsentGivenForVendor('iab:32')
            const criteoConsent = isConsentGivenForVendor('iab:91')

            const isConsentGivenForAdblockDetection = googleConsent && xandrConsent && criteoConsent
            if (isConsentGivenForAdblockDetection) {
                detectAdBlock()
            } else {
                postCounterMetric('NO_CONSENT')
            }
        }
    }, [isConsentGivenForVendor, isReady])
}
